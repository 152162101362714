import React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NewsTemplate = ({ data: { news } }) => {
  const featuredImage = getImage(news.featuredImage.node.localFile)

  return (
    <Layout isHomePage={true} headerClassName="bg-white" isLogoOneWhite={false} isLogoTwoWhite={false}>
      <Seo title={news.title} description={news.excerpt} />
      <main id="news-detail">
        <h2><StaticImage src="../images/ttl-news@2x.png" alt="ニュース" /></h2>
        <article
          className="inner"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <GatsbyImage image={featuredImage} alt={news.title} />
            <h1 itemProp="headline">{parse(news.title)}</h1>
            <p className="date">{news.date}</p>
          </header>
          {!!news.content && (<section itemProp="articleBody" className="news-body">{parse(news.content)}</section>)}
        </article>
        <Link className="link-btn" to="/news"><span>ニュース一覧へ</span></Link>
      </main>
    </Layout>
  )
}

export default NewsTemplate

export const pageQuery = graphql`
  query NewsById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current news by id
    news: wpNews(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
      }
    }
  }
`
